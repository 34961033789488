import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators';

const translations = {
    "it": 'DOMANDA:\nxxxxxxxxxx\nRISPOSTA:\nxxxxxxxxxxx',
    "en": 'QUESTION:\nxxxxxxxxxx\nANSWER:\nxxxxxxxxxxx',
    "es": 'PREGUNTA:\nxxxxxxxxxx\nRESPUESTA:\nxxxxxxxxxxx',
};

const model = {
    id: 0,
    bod_id: "helpdesk",
    last_update: 0,
    last_sync: 0,
    do_not_sync: false,
    lang: 'it',
    content: translations["it"],
    content_type: 'question',
    metadata: { bot_id: "helpdesk" }
};


export default {
    ...tools.models.apiCommons(model, null, ""),
    bootstrapContent: (oldContent, oldLang, newlang) => {
        if (oldContent !== "" && oldContent !== translations[oldLang]) {
            return oldContent;
        }
        return translations[newlang];
    },
    modelValidation: {
        model: {
            content: {
                required,
            },
        }
    },
    uploads: []
};
