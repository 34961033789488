<template>
    <div v-if='model && !model.is_null'>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>
        <form @submit='save' @change='formIsDirty'>

            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-4  md:col-3'>
                    <LanguageDropdown v-model='model.lang' placeholder='Lingua' />
                    <label class='sup'>Lingua</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <Textarea
                        v-model="v$.model.content.$model"
                        :autoResize="true"
                        rows="5"
                        :class="{'p-invalid': v$.model.content.$invalid && v$.model.content.$dirty}"
                        cols="30" />
                    <label>Testo completo</label>
                    <small>Per una corretta visualizzazione in lista è opportuno inserire la domanda nel secondo rigo (es DOMANDA: - a capo - Testo della domanda)</small>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-4  md:col-2'>
                    <BoolDropdown v-model='model.do_not_sync' placeholder='Disattiva' />
                    <label class='sup'>Elimina dal vector store</label>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Chatbots/HelpDesk');
import useVuelidate from '@vuelidate/core'

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import LanguageDropdown from '../../../components/4books/forms/LanguageDropdown.vue';
import BoolDropdown from '../../../components/4books/forms/BoolDropdown.vue';

export default {
    components: { BoolDropdown, LanguageDropdown },
    data(){
        return {};
    },
    mounted(){
        this.model = this.row;
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n){
            this.model = n;
        },
        'model.lang'(n, o) {
            this.model["content"] = model.bootstrapContent(this.model['content'], o, n);
        }
    },
    methods: {
        ...mapActions(['savePlain','createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }
            delete(this.model['translations']);
            this.model['metadata'] = model.modelCopy().metadata;

            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then( resp => {
                this.formIsClean();
                if (changeRoute){
                    this.navigateToId(resp.id).then( () => {
                        this.ShowSuccess('Ottimo', 'Domanda salvata con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Domanda salvata con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Navigations,
        Notifications
    ]
};
</script>
